import React, { useRef, useState, useCallback } from 'react'
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useTransition, animated } from 'react-spring'
import { useSwipeable } from 'react-swipeable'

import BlockQuote from "components/BlockQuote"
import Button from "components/Button"
import Container from "components/container"
import H3 from "components/typography/H3"
import H4 from "components/typography/H4"
import NextButton from "components/NextButton"
import P from "components/typography/P"
import ParagraphTitle from "components/ParagraphTitle"
import PrevButton from "components/PrevButton"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidDimensions from "utilities/fluidDimensions"
import fluidValues from "utilities/fluidValues"
import { serializers as linkSerializers } from "components/BaseLink.js"

const serializers = ({styles, rules}) => {
  return {
    types: {
      block: (props) => {
        const style = props.node.style
        if (style === 'normal') {
          return <P version='body'>{props.children}</P>
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles),
    }
  }
}

/**
 * Contributions Block
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *className={styles.image}
 * @return {jsx}
 */
const Contributions = ({title, slider, styles, rules}) => {
  const [current, set] = useState(0)
  const showPrevious = useCallback(() => current > 0 && set(current => (current - 1)), [current])
  const showNext = useCallback(() => current < (slider.length - 1) && set(current => (current + 1)), [current])
  const contentTransitions = useTransition(current, p => p, {
    from:  { opacity: 0},
    enter: { opacity: 1},
    leave: { opacity: 0, position: 'absolute'},
  })
  const transitions = useTransition(current, p => p, {
    from:  { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  })
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => showPrevious(),
    onSwipedLeft: (eventData) => showNext(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (<div className={styles.styles}>
    <Container extraLeft>
      <UnderlinedTitle extend={{styles: rules.title}}>{title}</UnderlinedTitle>
    </Container>

    <Container flex noPadding fullscreen>
      <div className={styles.container}>
        <div className={styles.sliderContainer} {...swipeHandlers}>
          {transitions.map(({ item, props, key }) => {
            return <animated.div className={styles.imageContainer} style={props} key={key}>
              <Img className={styles.image} image={slider[item].image.asset.gatsbyImageData} alt={""} />
            </animated.div>
          })}
        </div>
        <div className={styles.buttonContainer}>
          {slider.length > 0 && current > 0 && <PrevButton onClick={showPrevious} extend={{styles: rules.prevButton}}/>}
          {slider.length > 0 && current < (slider.length - 1) && <NextButton onClick={showNext} extend={{styles: rules.nextButton}}/>}
        </div>
      </div>
      <div className={styles.pageDots}>
        {slider && slider.map((image, index) =>
          <div key={index + "image"} className={styles.dot + (current == index ? " current" : "" )} onClick={() => set(index) }></div>
        )}
      </div>

      <div className={styles.contentContainer}>
        <Container fortyEighty flex withContentArea>
          {contentTransitions.map(({ item, props, key }) => {
            return <animated.div style={props} key={"content" + key}>
              <ParagraphTitle extend={{styles: rules.paragraphTitle}}>{slider[item].title}</ParagraphTitle>
              <BlockContent blocks={slider[item]._rawParagraph} serializers={serializers({rules, styles})} />
              {slider[item].link && <Button extend={{styles: rules.button}} href={slider[item].link.link}>{slider[item].link.label}</Button>}
            </animated.div>
          })}
        </Container>
      </div>
    </Container>
  </div>)
}

/* Styles */
const styles = (props) => ({
  container: {
    flex: '1 1 auto',
    textAlign: 'left',
    ...fluidDimensions({height: [308, 868]}),
    ...fluidValues({marginLeft: [-12, -16], marginRight: [-12, -16]}),
    phoneOnly: {
      width: '100%',
    }
  },
  sliderContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',

    phoneOnly: {
      width: '100%',
    }
  },
  imageContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%'
  },
  contentContainer: {
    width: '100%',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  title: {
    ...fluidValues({marginBottom: [48, 90]}),
  },
  paragraphTitle: {
    ...fluidValues({marginTop: [24, 60]}),
    marginBottom: 24,
  },
  button: {
    flex: '1 1 auto',
    ...fluidValues({marginTop: [32, 60]}),

    phoneOnly: {
      maxWidth: '100%',
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 4,
    position: 'relative',

    phoneOnly: {
      display: 'none'
    }
  },
  prevButton: {
    ...fluidValues({marginTop: [24, 40], marginRight: [12, 24]}),
  },
  nextButton: {
    ...fluidValues({marginTop: [24, 40]}),
  },
  pageDots: {
    ...fluidDimensions({width: [20, 80]}),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    phoneOnly: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
      marginTop: 20,
      maxWidth: '100%',
      order: 0,
      width: '100%',
    }
  },
  dot: {
    backgroundColor: colors.colorCanvas800,
    borderRadius: '50%',
    cursor: 'pointer',
    height: 8.7,
    marginBottom: 20,
    width: 8.7,

    phoneOnly: {
      marginLeft: 10,
      marginBottom: 0,
    },

    '&.current': {
      backgroundColor: colors.colorConsole500,
    }
  },
})

/* Component */
export default connectFela(styles)(Contributions)
