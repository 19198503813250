import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import Breadcrumb from 'components/Breadcrumb'
import ClientsBlock from "components/ClientsBlock"
import Contributions from "components/Contributions"
import Container from "components/container"
import IntroBlock from 'components/IntroBlock'
import Layout from "components/layout"
import P from "components/typography/P"
import PartnersBlock from "components/PartnersBlock"
import Slider from "components/Slider"
import UnderlinedTitle from "components/UnderlinedTitle"
import Values from "components/Values"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"

import H4 from "components/typography/H4"
import Link from "components/Link"
import Ul from "components/typography/UL"
import calc from "utilities/calc"
import { commonMarks } from "utilities/serializers"
import { useExtractHref } from "components/BaseLink"
import { serializers as linkSerializers } from "components/BaseLink.js"
import BlockContent from '@sanity/block-content-to-react'

const serializers = ({styles, rules}) => {
  return {
    list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
    listItem: props => {
      return <P tag={'li'} extend={{styles: rules.listItem}} version={"list"}>{props.children}</P>
    },
    block: (props) => {
      const style = props.node.style

      if (style === 'title') {
        return <H4 extend={{styles: rules.paragraphTitle}}>{props.children}</H4>
      }

      if (style === 'large') {
        return <P version={'large'} extend={{styles: rules.large}}>{props.children}</P>
      }

      return <P version={'body'} extend={{styles: rules.large}}>{props.children}</P>
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({styles, rules}),
    }
  }
}

const AboutusPage = ({ path, pageContext, data, rules, styles }) => {
  if (!data.sanityAboutusPage) {
    return null
  }
  const {
    title,
    meta,
    contenttitle,
    slug,
    header,
    intro,
    slider,
    values,
    contributions,
    clients,
    partners,
    _rawPartners,
    strapline,
    col1,
    col2,
    sliderTitle,
  } = data.sanityAboutusPage
  const au = pageContext.country == 'au';
  return (
    <Layout
      pageColor={colors.colorRoot50}
      linkColor={colors.colorRoot200}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      {header && <IntroBlock
                   header
                   extend={{styles: rules.header}}
                   image={header.image} />}

      <Container id={"why-hypr"} flex withContentArea5 tag={'section'} extend={{styles: rules.introContainer}}>
        <Breadcrumb leftPart={"ABOUT US"} extend={{styles: rules.breadcrumb}} />

        <UnderlinedTitle
          style={au ? 'neo-hero' : 'hero'} extend={{styles: au ? rules.neoTitle : rules.title}}>{contenttitle}</UnderlinedTitle>

        {!au ? <P version='large' extend={{styles: rules.intro}}>{intro}</P>:
         <Container>
           <P version='xlarge' neo extend={{styles: rules.neoStrapline}}>{strapline}</P>
           <P version='large' extend={{styles: rules.neoIntro}}>{intro}</P>

           <div className={styles.columns}>
             <BlockContent
               renderContainerOnSingleChild
               className={styles.col1}
               blocks={col1}
               serializers={serializers({rules, styles})} />

             <BlockContent
               renderContainerOnSingleChild
               className={styles.col2}
               blocks={col2}
               serializers={serializers({rules, styles})} />
           </div>
         </Container>}
      </Container>

      <Container onlyLeft extend={{styles: rules.slider}} id={"our-team"}>
        <Slider title={sliderTitle} images={slider} />
      </Container>

      {values && <Container withContentArea5 tag={'section'} extend={{styles: rules.valuesContainer}} id={"our-principles"}>
                   <Values values={values.values} title={values.title} intro={values.intro} />
                 </Container>}

      {contributions && <Contributions
                          extend={{styles: rules.contributions}}
                          title={contributions.title}
                          slider={contributions.slider} />}

      {clients && <Container id={'clients'} extraLeft withContentArea5 tag={'section'} extend={{styles: rules.clients}}>
                    <ClientsBlock title={clients.title} />
                  </Container>}

      {partners && <Container id={'partners'} extraLeft withContentArea5 tag={'section'} extend={{styles: rules.partners}}>
                     {partners && <PartnersBlock
                                    pageColor={'Root'}
                                    extend={{styles: rules.partners}}
                                    title={partners.title}
                                    content={_rawPartners.content}
                                    featured={partners.partners}
                                    circle />}
                   </Container>}
    </Layout>
  )
}

const styles = () => ({
  columns: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 30,
    '@media(min-width: 769px)': {
      flexWrap: 'nowrap',
    },
    width: calc(256, 507, 320, 768),
    tabletPortraitAndUp: {
      width: calc(507, 851, 768, 1440),
    },
    maxWidth: 851,
    ...fluidValues({
      marginTop: [32, 60],
      marginLeft: [0, 133]
    }),
  },
  col1: {
    flex: '1 1 50%',
    '& h4': {
      marginTop: 0
    }
  },
  col2: {
    flex: '1 1 50%',
    '& h4': {
      marginTop: 0
    }
  },
  neoStrapline: {
    ...fluidValues({
      width: [233, 734],
      marginBottom: [48, 90],
      marginLeft: [0, 30]
    })
  },
  neoIntro: {
    marginBottom: 0,
    marginTop: 0,
    ...fluidValues({
      // marginBottom: [48, 90],
      marginLeft: [0, 133]
    }),
    width: calc(256, 507, 320, 768),
    tabletPortraitAndUp: {
      width: calc(507, 851, 768, 1440),
    },
    maxWidth: 851
  },
  header: {
    zIndex: 11,
  },
  introContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    ...fluidValues({paddingTop: [60, 120], paddingBottom: [60, 120]}),
  },
  breadcrumb: {
    flex: '0 0 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  neoTitle: {
    flex: '0 0 auto',
    ...fluidValues({
      marginBottom: [48, 90]
    })
  },
  intro: {
    flex: '0 1 510px',
    marginLeft: 0,

    tabletPortraitAndUp: {
      margin: '0 auto',
    },

    desktopAndUp: {
      marginLeft: 98,
    }
  },
  slider: {
    overflow: 'hidden',
    ...fluidValues({marginBottom: [120, 220]})
  },
  valuesContainer: {
    ...fluidValues({marginBottom: [90, 200]})
  },
  expTitle: {
    ...fluidValues({marginBottom: [48, 90]}),
  },
  expintro: {
    marginTop: 0,
    maxWidth: 733,
    position: 'relative',
    zIndex: 1,
    extend: {
      ...fluidValues({marginLeft: [25, 326], marginBottom: [60, 120]}),
    }
  },
  experimentParagraphContainer: {
    '& > p': {
      phoneOnly: {
        paddingLeft: 0,
      }
    }
  },
  sillyimage: {
    '@media(max-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  expimage: {
    width: '100%',
  },
  experimentImageContainer: {
    flex: '1 1 37%',
    minWidth: 234,

    '@media(max-width: 633px)': {
      order: 0,
      /* flex: '1 1 35%', */
      margin: '0 auto',
    },
    /* '@media(min-width: 950px)': {
     *   flex: '0 1 49%',
     * } */
  },
  experimentTextContainer: {
    position: 'relative',
    flex: '0 1 40%',
    marginLeft: 'auto',
    ...fluidValues({
      marginLeft: [21, 110],
      paddingRight: [20, 34]
    }),
    maxWidth: 543,

    '@media(max-width: 633px)': {
      order: 1,
      margin: '0 auto',
      flex: '1 1 10%',
      paddingRight: '0 !important',
      ...fluidValues({
        paddingLeft: [24, 110]
      }),
      onBefore: {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 1,
        height: '77%',
        backgroundColor: 'black',
        left: 0,
      }
    },
  },
  paragraphTitleContainer: {
    phoneOnly: {
      border: 'none',
      marginTop: '0 !important',
      extend: {
        ...fluidValues({
          marginLeft: [-24, -110],
        })
      }
    }
  },
  paragraphTitle: {
    extend: {
      ...fluidValues({marginTop: [47, 70]}),
    }
  },
  expcontent: {
    ...fluidValues({paddingBottom: [60, 120], marginTop: [-31, -117]}),
    flexWrap: 'unwrap',
    paddingRight: 0,

    phoneOnly: {
      flexWrap: 'wrap',
      width: '100%',
    },

    '@media(min-width: 600px) and (max-width: 1200px)': {
      paddingRight: 0,
    }
  },
  button: {
    flex: '1 1 auto',
    ...fluidValues({marginTop: [36, 60]}),

    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      maxWidth: '100%',
      width: '100%',
    }
  },
  contributions: {
    ...fluidValues({marginBottom: [60, 120]}),
  },
  clients: {
    ...fluidValues({marginBottom: [90, 200]})
  },
  normal: {
    phoneOnly: {
      paddingLeft: 20,
      paddingRight: 20,
    }
  },
  partners: {
    display: 'block',
    ...fluidValues({marginBottom: [42, 110]})
  },
})

export default connectFela(styles)(AboutusPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityAboutusPage(countrySet: { eq: $country }) {
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      contenttitle
      slug {
        current
      }
      header {
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
      }

      intro
      strapline
      col1: _rawCol1Content
      col2: _rawCol2Content

      sliderTitle
      slider {
        credits
        asset {
            gatsbyImageData(layout: CONSTRAINED, width:  515)
        }
      }
      values {
        title
        intro
        values {
          title
          content
          image {
            asset {
            gatsbyImageData(layout: CONSTRAINED, width:  324)
            }
          }
        }
      }

      contributions {
        title
        slider {
          title
          _rawParagraph,
          link {
            ...sanityLinkFrag
          }
          image {
            credits
            asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
            }
          }
        }
      }

      clients {
        title
      }

      _rawPartners(resolveReferences: {maxDepth: 10})
      partners {
        title
        partners {
          name
          description
          link {
            ...sanityLinkFrag
          }
        }
      }
    }
  }
`
