import React from 'react'
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'

import fluidValues from "utilities/fluidValues"
import ParagraphTitle from "components/ParagraphTitle"
import P from "components/typography/P"

/**
 * Value Block
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *className={styles.image}
 * @return {jsx}
 */
const value = ({image, title, content, styles, rules, odd}) => {
    const imageSrc = image.images ? image : image.asset.gatsbyImageData

    return <div className={styles.container}>
    <div className={styles.imageContainer}>
      {imageSrc && <Img alt={title} objectFit={'contain'} className={styles.image} image={imageSrc} />}
    </div>
    <div className={styles.contentContainer}>
      <ParagraphTitle extend={{container: rules.overrideTitle}}>{title}</ParagraphTitle>
      <P version="medium" extend={{styles: ({fontWeight: 400})}}>{content}</P>
    </div>
  </div>
 }

/* Styles */
const styles = (props) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',

    '@media(max-width: 750px)': {
      paddingLeft: 0,
    }
  },
  overrideTitle: {
    marginTop: '0 !important',
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 60%',
    justifyContent: 'center',
    margin: '0 auto 48px auto',
    maxWidth: '60%',
    order: 0,

    tabletPortraitAndUp: {
      flex: '1 1 20%',
      justifyContent: (props.odd ? 'flex-start' : 'center'),
      marginLeft: (props.odd ? 0 : 60),
      marginRight: (props.odd ? 60 : 0),
      order: (props.odd ? 0 : 1),
    },
  },
  image: {
    maxWidth: 314,
    width: '100%',
  },
  contentContainer: {
    flex: '1 1 40%',

    ...fluidValues({
      marginLeft: [21, 110],
      marginTop: [-12, 20],
    }),

    phoneOnly: {
      marginLeft: 20,
    }
  },
})

/* Component */
const Value = connectFela(styles)(value)
export default Value
